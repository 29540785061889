import apiInstance from '@/api-requests/api-instance';
import { useAuthContext } from '@/contexts/auth';
import { useOnboardingContext } from '@/contexts/onboarding';
import {
  GetOnboardingStateResponse,
  OnboardingState,
} from '@/types/onboarding';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

const HomePage = () => {
  const { accessToken } = useAuthContext();
  const { navigateToPath, setOnboardingState } = useOnboardingContext();

  const { data } = useQuery({
    enabled: !!accessToken,
    queryFn: async () => {
      const { data } =
        await apiInstance.get<GetOnboardingStateResponse<OnboardingState>>(
          '/v0/onboarding/',
        );
      return data;
    },
    queryKey: ['onboardingData'],
  });

  useEffect(() => {
    if (data?.state) {
      setOnboardingState(data.state);
      navigateToPath(data.state.page);
    }
  }, [data, setOnboardingState, navigateToPath]);

  return <></>;
};

export default HomePage;
